.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

.not-found-content {
  max-width: 700px;
  padding: 30px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.not-found-content h1 {
  font-size: 6rem;
  margin: 0;
  color: #dc3545;
}

.not-found-content h2 {
  font-size: 2rem;
  margin: 10px 0;
  color: #343a40;
}

.not-found-content p {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.home-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 4px;
}

.home-link:hover {
  background-color: #0056b3;
}
