.search-input-group {
  width: 50%;
  position: relative;
}

.search-input-group .form-control {
  padding-left: 10px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
